import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";

const LogoContainer = styled.div`
  padding: 4rem;
  max-width: 30rem;
  margin: 0 auto;
`;

const LightSection = styled.div`
  background: #115876;
  padding: 2rem;
  margin: 0 auto;
  @media (min-width: 900px) {
    padding: 3rem 6rem;
  }
  @media (min-width: 1500px) {
    padding: 3rem 12rem;
  }
`;

export default function IndexPage(params) {
  return (
    <Layout>
      <title>Home Page</title>
      <LogoContainer>
        <StaticImage src="../images/logo.png" alt="N.O.R.P.P.A logo" />
      </LogoContainer>
      <LightSection>
        <h2>Mikä N.O.R.P.P.A?</h2>
        <p>
          N.O.R.P.P.A eli Nihilistinen ja Okkultistinen Realisaatio
          Perustarpeiden Parantamisesta Asiainyhdistys (Nihilistic and
          Occultistic Realization of Primitive Primary needs Association).
          Olemme yhteisö, joka pyrkii valaistumiseen sekä iänkaikkiseen,
          elämänmakuiseen elämään
        </p>
        <ul>
          <li>
            elämäntapamuutos tiiviin tukipiirin ja hengellisen ohjauksen
            avustuksella
          </li>
          <li>täysin riskivapaa sekä aivopesuvapaa vaihtoehto</li>
          <li>yhteisömme edustaa nykyaikaisia arvoja </li>
          <li>
            uskomme vakaasti Cthulhuun, pyhiin Norppajumaliin, sekä heidän
            lahna-armeijaansa
          </li>
          <li>
            kannustava hierarkinen arvojärjestelmä, selkeät elämänohjeet sekä
            helppohoitoinen uhrilahjaohjelma
          </li>
          <li>
            ennen kaikkea edustamme sinua, syyllisyydentuskista liberoitunutta
            norpanalkua!
          </li>
        </ul>
      </LightSection>
    </Layout>
  );
}
